<template>
  <div class="container">
    <h1>About TezTools</h1>
    <p>Passion driven data and infrastructure services for the Tezos blockchain.</p>
    <div class="row">
      <div class="offset-2 col-8">
        <h3>What?</h3>
        <p>
          Carrier-grade data and infrastructure services for the Tezos blockchain built using
          battle-tested service patterns crafted from decades of team experience building critical
          Internet platforms. Whether you have heard of TezTools or not, chances are high that you
          have indirectly used one of it's core API or infrastructure services without knowing it.
        </p>
        <p>
          Core service offerings are dedicated API data feeds and dedicated nodes (Tezos, TZKT, and BCD nodes).
        </p>
        <p>
          Custom service offerings include dedicated index hosting, custom index development,
          and infrastructure consultation.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="offset-2 col-8">
        <h3>Why?</h3>
        <p>
          We care greatly about the Tezos technologies, ecosystem, and community.
          We are huge fans of the interesting decentralized applications and projects
          that have chosen Tezos as their home.
          We love the thriving and positive Tezos community.
        </p>
        <p>
          So when you love something, you do everything you can to support and help it.
          You dive right in and start finding the places you are useful and give.
          And that's just what we have done. We are working to provide vital data and
          infrastructure services because we know that Tezos and its participants are great,
          and we wish to make it even better.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="offset-2 col-8">
        <h3>Who?</h3>
        <p>
          We are cryptocurrency fans and systems engineers with decades of experience in
          large applications and high speed data infrastructure in everything from high
          finance and e-commerce to robotics and power plants. We have a passion of smooth
          and reliable user experiences and are dedicated to provide solutions and tools
          that dapp developers and teams small to large can depend on.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
